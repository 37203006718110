import React,{Component} from 'react'
import {Row,Col} from 'react-bootstrap'
import Facebook from "./facebook";
class SocialMedia extends Component{
    render(){
        return(
          <Row>
              <Col sm={6} smOffset={3} className={"col-centered"}>
                  Volg mij op:
                  <Facebook link={this.props.facebook}/>
              </Col>
          </Row>
        );
    }
}
export default SocialMedia