import React, { Component } from "react";
import { Col } from "react-bootstrap";
import "../../stylesheets/card.css";
import calendar_img from "../../imgs/icons/icons8-calendar.svg";
class Card extends Component {
  render() {
    const bg = {
      backgroundImage: "url(" + this.props.data.img + ")",
    };
    return (
      <Col sm={3} className="card-container">
        <div
          className={`card ${this.props.data.img ? "" : "card--nobg"}`}
          style={this.props.data.img ? bg : {}}
        >
          <div className="card-overlay">
            <div className="caption">
              <span>{this.props.data.title}</span>
            </div>
            <div className="footer">
              <img src={calendar_img} alt="calendar" />
              <span>{this.props.data.date}</span>
            </div>
          </div>
        </div>
      </Col>
    );
  }
}

export default Card;
