import React, { Component } from "react";
import { Grid, Row, Col, Image } from "react-bootstrap";
import img_head from "../../imgs/profile.jpg";
import "../../stylesheets/about.css";

class About extends Component {
  render() {
    return (
      <Grid>
        <Row>
          <Col>
            <p className="description">
              Mijn naam is Nathalie Demuynck.Ik ben geboren en getogen in
              Wevelgem(°1968). <br /> <br />
              Sinds 2017 fotografeer ik doelgericht.Dit aanvankelijk om zelf
              eens vanuit een ander perspectief de wereld te bekijken. <br />
              Het‘ kaderen’ met je camera, dwingt je als fotograaf echt wel
              letterlijk en figuurlijk om stil te staan bij wie en wat je
              omringt. <br /> <br />
              Triggeren met een andere invalshoek en buiten een gangbaar
              referentiekader treden: hoe ervaar jij jouw omgeving en jezelf.Hoe
              ga je hier mee om ?
              <br />
              De toeschouwer vrijheid gunnen om die momentopname persoonlijk te
              interpreteren, fantasie stimuleren…. <br /> <br />
              Hierbij primeert voor mij emotie, sfeerbeleving en het verhaal
              achter het beeld boven een technisch perfect plaatje. <br />{" "}
              <br />
              Prikkelen en‘ bewust zijn’ verruimen, met een niet klassieke
              fotografische blik, vind ik gewoonweg fijn.Dit delen met anderen
              schenkt mij voldoening. <br />
              <br />
              Ik hoop van harte dat ook jij hiervan geniet!
            </p>{" "}
            <Image
              src={img_head}
              alt={"Nathalie"}
              responsive
              circle
              className="profile"
            ></Image>{" "}
          </Col>{" "}
        </Row>{" "}
      </Grid>
    );
  }
}

export default About;
