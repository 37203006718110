import React, { Component } from "react";
import Card from "./card";
import { Grid, Row } from "react-bootstrap";

const data = [
  {
    date: 2018,
    title: "Krottegem",
    ref: "krottegem",
  },
  {
    date: 2018,
    title: "Barak futur",
    ref: "bf",
  },
];

class ProjectList extends Component {
  render() {
    const listItems = data.map((object, index) => (
      <Card data={object} key={index} />
    ));
    return (
      <Grid>
        <Row>{listItems}</Row>
      </Grid>
    );
  }
}

export default ProjectList;
