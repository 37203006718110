import React, { Component, Fragment } from "react";
import "../stylesheets/App.css";

import f1 from "../imgs/foto1.jpg";
import f2 from "../imgs/foto2.jpg";
import f3 from "../imgs/foto3.jpg";
import f4 from "../imgs/foto4.jpg";

import fg1 from "../imgs/gallerij/foto1.jpg";
import fg2 from "../imgs/gallerij/foto2.jpg";
import fg3 from "../imgs/gallerij/foto3.jpg";
import fg4 from "../imgs/gallerij/foto4.jpg";
import fg5 from "../imgs/gallerij/foto5.jpg";
import fg6 from "../imgs/gallerij/foto6.jpg";
import fg7 from "../imgs/gallerij/foto7.jpg";
import fg8 from "../imgs/gallerij/foto8.jpg";
import fg9 from "../imgs/gallerij/foto9.jpg";
import fg10 from "../imgs/gallerij/foto10.jpg";
import fg11 from "../imgs/gallerij/foto11.jpg";
import fg12 from "../imgs/gallerij/foto12.jpg";
import fg13 from "../imgs/gallerij/foto13.jpg";
import fg14 from "../imgs/gallerij/foto14.jpg";
import fg15 from "../imgs/gallerij/foto15.jpg";
import ParallaxElement from "./components/parallaxElement";
import Navigation from "./components/navigation";
import ContactForm from "./components/contactform";
import ProjectList from "./components/projectList";
import Gallery from "./components/gallery";
import SocialMedia from "./components/socialmedia";
import Footer from "./components/footer";
import About from "./components/about";

class App extends Component {
  render() {
    return (
      <Fragment>
        <Navigation />
        <div className="parallax-container">
          <ParallaxElement
            id={"about"}
            isTitleElement={true}
            pic={f1}
            title={"La conscience"}
            sectionTitle={"Over"}
          >
            <About />
          </ParallaxElement>
          <ParallaxElement
            id={"projects"}
            pic={f2}
            title={"Met open hart"}
            sectionTitle={"Mijn realisaties"}
          >
            <ProjectList />
          </ParallaxElement>
          <ParallaxElement
            id={"gallery"}
            pic={f3}
            title={"Onbevangen en onvoorwaardelijk"}
            sectionTitle={"Gallerij"}
          >
            <Gallery
              imgs={[
                fg1,
                fg2,
                fg3,
                fg4,
                fg5,
                fg6,
                fg7,
                fg8,
                fg9,
                fg10,
                fg11,
                fg12,
                fg13,
                fg14,
                fg15,
              ]}
            />
          </ParallaxElement>

          <ParallaxElement
            id={"contact"}
            pic={f4}
            title={"Kuierend op pad"}
            sectionTitle={"Contact"}
          >
            <ContactForm email={"alex_dhaenens@hotmail.com"} next={"next"} />
            <SocialMedia facebook="https://www.facebook.com/La-conscience-245836912627291" />
            <Footer />
          </ParallaxElement>
        </div>
      </Fragment>
    );
  }
}

export default App;
