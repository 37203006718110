import React, { Component } from 'react'
class GalleryImage extends Component {
    render() {
        return (
            <a href={this.props.url} target="_blank" className="gallery-image-link">
                <img className="gallery-image" src={this.props.url} alt={this.props.url} />
            </a>
            //<Thumbnail src={this.props.data.url} href={this.props.data.url}/>

        );
    }
}

export default GalleryImage;
