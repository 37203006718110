import React,{Component} from 'react'
import "../../stylesheets/socialButton.css"
import img from "../../imgs/icons/icons8-facebook.svg"
class Facebook extends Component{
    render(){
        return(
            <a href={this.props.link} className="social_button">
                <img src={img} alt="facebook"/>
            </a>

        )
    }
}

export default Facebook