import React, {Component} from 'react';
import {Form, FormGroup, FormControl, Button, Col} from 'react-bootstrap';
import "../../stylesheets/contactform.css"
class ContactForm extends Component {

    render() {
        return (
            <Form horizontal action={"https://formspree.io/"+this.props.email} method="POST">
                <FormGroup controlId="controlName">
                    <Col sm={6} smOffset={3}>
                        <FormControl type="text" placeholder="Naam" onChange={this.handleInputChange}  name="Name"/>
                    </Col>
                </FormGroup>
                <FormGroup controlId="controlEmail">
                    <Col sm={6} smOffset={3}>
                        <FormControl type="email" placeholder="Email" onChange={this.handleInputChange} name="Email"/>
                    </Col>
                </FormGroup>
                <FormGroup controlId="controlMessage">
                    <Col sm={6} smOffset={3}>
                        <FormControl componentClass="textarea" placeholder="Bericht" onChange={this.handleInputChange} name="Message"/>
                    </Col>
                </FormGroup>
                <input type="hidden" name="_next" value={this.props.next} />
                <FormGroup>
                    <Col sm={10} smOffset={3}>
                        <Button type="submit">Verzenden</Button>
                    </Col>
                </FormGroup>
            </Form>
        );
    }
}

export default ContactForm;