import React, {Component} from 'react';
import {Navbar, NavItem, Nav} from 'react-bootstrap';
import "../../stylesheets/navbar.css"
class Navigation extends Component {
    constructor(){
        super();
        this.handleScroll=this.handleScroll.bind(this)
        this.state={
            isVisible:false
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event){
        let supportPageOffset = window.pageXOffset !== undefined;
        let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
        let scroll = {
            x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
            y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
        };

        let is_visible=this.state.isVisible;
        if (scroll.y > window.screen.height) {
            if(!is_visible){
                this.setState({isVisible:true});
            }
        } else {
            if(is_visible){
                this.setState({isVisible:false});
            }
        }
    }
    render() {
        return (
            <Navbar fixedTop className={this.state.isVisible?"visible":"not-visible"}>
                <Navbar.Header>
                    <Navbar.Brand>
                        <a href="/"><span>La conscience</span></a>
                    </Navbar.Brand>
                    <Navbar.Toggle/>
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav pullRight>
                        <NavItem href="#about">
                            Over
                        </NavItem>
                        <NavItem href="#projects">
                            Realisaties
                        </NavItem>
                        <NavItem href="#gallery">
                            Gallerij
                        </NavItem>
                        <NavItem href="#contact">
                            Contact
                        </NavItem>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Navigation;