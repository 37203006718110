import React, { Component, Fragment } from 'react'
import { Grid, Row, Col, Button } from 'react-bootstrap'
import "../../stylesheets/gallery.css"
import "../../stylesheets/grid.css"
import GalleryImage from './gallery_image'

class Gallery extends Component {
    render() {
        return (
            <Fragment>
                <div className="grid-container">
                    {this.props.imgs && this.props.imgs.map((img, index) => <div className={`grid-item grid-item-${index}`}> <GalleryImage url={img} /></div>)}
                </div>
                <Grid>
                    <Row>
                        <Col sm={2} smOffset={5} style={{ textAlign: "center" }}>
                            <a href="https://www.facebook.com/La-conscience-245836912627291/" className="btn btn-default" target="_blank">Meer</a>
                        </Col>
                    </Row>
                </Grid >
            </Fragment >
        );
    }
}

export default Gallery
