import React, { Component, Fragment } from "react";
import { Grid, PageHeader } from "react-bootstrap";
import "../../stylesheets/parallaxElement.css";
class ParallaxElement extends Component {
  render() {
    const bg = {
      backgroundImage: "url(" + this.props.pic + ")",
    };
    return (
      <Fragment>
        <div className="parallax" style={bg}>
          <div
            className={
              "parallax-title-container" +
              (this.props.isTitleElement ? " title-element" : "")
            }
          >
            <span
              className={
                "parallax-title" +
                (this.props.isTitleElement ? " bordered large " : "")
              }
            >
              {this.props.title}
            </span>
          </div>
        </div>
        <section id={this.props.id}>
          <Grid>
            <PageHeader>{this.props.sectionTitle}</PageHeader>
            {this.props.children}
          </Grid>
        </section>
      </Fragment>
    );
  }
}

export default ParallaxElement;
